
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { authHeader } from '../../../helpers/fakebackend/auth-header';
import {
   required 
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "Restaurant Wise Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect,DatePicker },
  data() {
    return {
      replyField: "",
      tableData: [],
      tableData2: [],

      title: "Restaurant Wise Report",
      items: [
        {
          text: "View",
        },
        {
          text: "Restaurant Wise Report",
          active: true,
        },
      ],
      restaurants:[],
      branches:[],
      cityArr:[],
      city:"",
      topSellingProducts:[],
      daterange:[],
      countryArr:[],
      country:"",
      averageRating:"",
      brandArr:[],
      brandData:"",
      restBranchID:"",

      // restaurantID:(sessionStorage.getItem('restID')>0)? [{'restID':sessionStorage.getItem('restID'),'name':sessionStorage.getItem('restName')}] : "",
      // branchID:(sessionStorage.getItem('branchID')>0) ? [{'restBranchID':sessionStorage.getItem('branchID'),'branchName':sessionStorage.getItem('branchName')}] : "",
      restaurantID:"",
      branchID:"",
      feedbackIdhold:"",
      repliedTextArr:[],
      repliedText:"",
      repliedBy:"",
      submitted: false,
      showDismissibleAlert :false,
      showDismissibleAlertMutUnmut:false,
      loading: false,
    
    // datatable 1 & 2 ///////
    sortBy:"age",
    totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100,500,1000,2000,"All"],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "Reply", 
          sortable: false,
        },
        {
          key: "Action", 
          sortable: false,
        },
        {
          key: "review", 
          sortable: true,
        },
        {
          key: "rating", 
          sortable: true,
        },
        {
          key: "orderId", 
          sortable: true,
        },
        {
          key: "userName",
          lable: "Customer Name",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
        {
          key: "allReplies",
          sortable: true,
        },
        
       
        
      ],
      ////////// datatable 3 category //////////////////

        // datatable 1 & 2 ///////
         sortBy2:"age",
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 25, 50, 100],
      filter2: null,
      filterOn2: [],
      sortDesc2: false,
      companyArr:[],
      restBranchArr:[],
      companyID:0,
      fields2: [

        {
          key: "category",
          sortable: true,
        },
        {
          key: "orders",
          sortable: true,
        },
      
        {
          key: "totalItemPrice",
          sortable: true,
        },
        {
          key: "totalItemTax",
          sortable: true,
        },
        {
          key: "totalRevenue",
          sortable: true,
        },
       
        
      ],
    };
  },

   validations: {
    
      replyField: {
        required,
      }
  
  },
   computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    rows2() {
      return this.tableData2.length;
    },
  },
  mounted() {
       
       this.getAllRestaurantBranches();
    // Set the initial number of items
    this.totalRows = this.items.length;
   // this.readRestaurantMenu();
   // this.getRestaurantList();

      if(this.$storageData.profile.empTypeID==1 ){
        this.getAllCountry();
      }
      else if(this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11){
         this.getRestaurantCountry();
      }else{
          this.getFeedbackReview();
      }
  },

  // filters: {
  //       truncate: function (text, length, suffix) {
  //           if (text.length > length) {
  //               return text.substring(0, length) + suffix;
  //           } else {
  //               return text;
  //           }
  //       },
  //   },
  methods: {

    getAllRestaurantBranches(){
 
    this.axios.post(this.$loggedRole+"/getAllRestaurantBranches")
          .then((result)=>{
            this.restBranchArr = result.data.data;
              
        });
},
 
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCountry(){
      this.countryArr = [];
      this.country = "";
        this.axios
        .post(this.$loggedRole+"/getAllCountry"
        )
        .then((response) => {
                this.countryArr = response.data.data;
                // this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getAllCities();
                
        });
    },
    getAllCities(){
      this.cityArr = [];
      this.city  = "";
         this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                // this.city = (this.cityArr) ? this.cityArr[0] : "";
                if(this.$storageData.profile.empTypeID==1){  // super admin
                  // this.getRestaurantBrands();
                  this.getAllCompanies();
                }
                if(this.$storageData.profile.empTypeID==11){  // food company login
                  this.getAllCompanies();

                    this.getRestaurantBrandsByCompanyID();
                }else{
                     this.readRestaurantBranchData();
                } 
        });
    },

    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    getRestaurantBrands(){
        this.axios.post(this.$loggedRole+"/getRestaurantList",
          {'restID':this.$storageData.profile.restID,'restBranchID':this.$storageData.profile.restBranchID,'empTypeID':this.$storageData.profile.empTypeID})
          .then((result)=>{
            this.brandArr = result.data.data;
            this.brandData = (this.brandArr) ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
              
        });
    },
     getRestaurantBrandsByCompanyID(){
       this.brandArr = [];
       this.brandData = "";
       var companyID = this.companyID;
      if(this.$storageData.profile.empTypeID==11)
      {
        companyID = this.$storageData.profile.companyID;
      }
         this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {companyID:companyID}
        )
        .then((response) => {
                this.brandArr = response.data.data;
                // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
                this.readRestaurantBranchData();
                
        }); 
    },
    getRestaurantCountry(){
        this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {restID:this.$storageData.profile.restID,
          }
        )
        .then((response) => {
                this.countryArr = response.data.data;
                this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getAllCompanies();
                
        });
    },
    getRestaurantCities(){

      var restID  = this.$storageData.profile.restID;
      if(this.brandData.restID && this.brandData.restID != "")
      {
        restID = this.brandData.restID;
      }
         this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {restID:restID,
          country: (this.country) ? this.country.country : "", 
          
          }
        )
        .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                if(this.$storageData.profile.empTypeID==11){
                    this.getRestaurantBrandsByCompanyID();
                }else{
                     this.readRestaurantBranchData();
                }  
        }); 
    },
    getAllCompanies()
    {
      var companyID = 0;
      if(this.$storageData.profile.empTypeID==11)
      {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies",{companyID:companyID})
        .then((response) => {
         this.companyArr = response.data.data;
        // this.companyID = (this.companyArr) ? this.companyArr[0] :"";
        //  this.branchID = (this.branches) ? this.branches[0] :"";
        //  this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
        //  this.getProductMixReport();
          
        });
    },
    readRestaurantBranchData(){
         this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {restID :(this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
          'restBranchID':0,'empTypeID':this.$storageData.profile.empTypeID,
            city: (this.city) ? this.city.city : "",
            
            
          }
        )
        .then((response) => {
         this.branches = response.data.data;
         this.branchID = (this.branches) ? this.branches[0] :"";
         this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
         this.getFeedbackReview();
          
        });
    },
    onChangeRestBrand(){
  
      this.readRestaurantBranchData();
      this.getRestaurantCities();
    },
    onchangeBranch(){
      this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
     this.getFeedbackReview();
    },
    clearFilter(){
      this.daterange = [];
      this.getProductMixReport();
      this.getFeedbackReview();
    },


    giveReply(feedbackId){
      
       this.feedbackIdhold=feedbackId;
        this.$root.$emit("bv::show::modal", "modal-lg-reply");
        
    },

    // seeDetailsReply(text,repliedby){
      
    //    this.repliedText=text;
    //    this.repliedBy=repliedby;

    //     this.$root.$emit("bv::show::modal", "modal-lg-repliedDetails");
        
    // },
    seeDetailsReply(allReplies){
   
       this.repliedTextArr=allReplies;
      //  this.repliedBy=repliedby;

        this.$root.$emit("bv::show::modal", "modal-lg-repliedDetails");
        
    },
     muteFeedback(feedbackId){
        let formData = new FormData();          
          formData.append('feedbackID', feedbackId);           
         this.axios.post(this.$loggedRole+"/muteFback",formData, {
          headers: authHeader()
        })
           .then((result)=>{           
            //  console.log(result);
           this.status = result.data.data;       
           this.showDismissibleAlertMutUnmut = true;
            location.reload();
              
        })
        .catch((error)=> {
         this.loading = false;                      
                        alert(error.response.data.data);
                    });
        
    }, 

    unMuteFeedback(feedbackId){
      let formData = new FormData();          
          formData.append('feedbackID', feedbackId);           
         this.axios.post(this.$loggedRole+"/unmuteFback",formData, {
          headers: authHeader()
        })
           .then((result)=>{           
             //console.log(result);          
           this.status = result.data.data;       
           this.showDismissibleAlertMutUnmut = true;
            location.reload();
              
        })
        .catch((error)=> {
         this.loading = false;                      
                        alert(error.response.data.data);
                    });
        
    },

    saveReply(){
 
      this.submitted = true;
       this.$v.$touch();
      if (this.$v.$invalid) {
         window.scrollTo(0,0); 
         
         this.replyField="";
        return;
      } else {
        
        this.loading = true;
        // alert(this.feedbackIdhold);
        // alert(this.replyField);

        
         let formData = new FormData(); 
          
            formData.append('feedbackID', this.feedbackIdhold);
            formData.append('replyText', this.replyField);
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);
         this.axios.post(this.$loggedRole+"/addReply",formData, {
          headers: authHeader()
        })
          .then((result)=>{
            this.replyField="";
            this.feedbackIdhold="";
          
           this.status = result.data.data;
       
           this.showDismissibleAlert = true;
           this.loading = false;
           this.$root.$emit("bv::hide::modal", "modal-lg-reply");
          setTimeout(function() { location.reload();},4000);
          //  location.reload();

           // this.orderItemAddonArr = result.data.orderItemAddonArr;
              
        })
        .catch((error)=> {
       this.loading = false;
                      
                        alert(error.response.data.data);
                    });

     }
    },


    // getProductMixReport(){
    //       this.axios.post(this.$loggedRole+"/productMixReport",
    //         {'restID':(this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
    //             'restBranchID':this.restBranchID,
    //             'empTypeID':this.$storageData.profile.empTypeID,
    //             'daterange':this.daterange,
    //         })
    //           .then((result)=>{
               
    //             this.topSellingProducts = result.data.data;
              
    //         });
    //   },

    getFeedbackReview(){
      // alert(this.restBranchID);
                 this.axios
        .post(this.$loggedRole+"/getFdbackReview", {
          'restBranchId':this.restBranchID.restBranchID,
          'daterange':this.daterange,
           
          }
        )
        .then((response) => {

           this.tableData = response.data.data;
           this.averageRating = response.data.Avg;
           //alert(this.tableData);
           
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>  
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
      Reply Added Successfully!
    </b-alert>
     <b-alert v-model="showDismissibleAlertMutUnmut" variant="success" dismissible>
      Feedback Updated Successfully!
    </b-alert>
  </div>
    <PageHeader :items="items" />
  <!--FILTER START------>  
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
          <div class="inner mb-2 row" >

              <div class="col-md-6">
                        <label class="form-label" for="formrow-endTime-input">Select Restaurant Branch*</label>
                        <multiselect v-model="restBranchID"  :options="restBranchArr" track-by="restBranchID" label="restaurantName"
                          @input="getFeedbackReview();" ></multiselect>
                        <!-- <div
                          v-if="submitted && $v.restBranchID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.restBranchID.required"
                            >This value is required.</span
                          >
                        </div> -->
                    </div> 
           
<!-- Date range -->
                <div class="col-md-6"  style="width:30%">   
                            <label>Date Range </label>
                                <date-picker v-model="daterange" format="DD MMM Y" value-type="format" range append-to-body lang="en" confirm @input="onchangeBranch();"></date-picker>
                </div>
<!-- Date range END-->

<!-- Clear filter -->
                <div class="col-md-2"  style="width:16%">   
                 <button v-if="daterange.length>0" class="btn btn-themeBrown mt-4"  v-on:click="clearFilter()"> Clear Filter </button>
                </div>
<!-- Clear filter END-->
        </div>
   </div>
 </div>
<!--FILTER END------>

<div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <h4>AVERAGE :</h4><span><h3>{{this.averageRating}}</h3></span>
                    </div>
                  </div>
                </div>
            </div>
</div>
     <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  
                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                        
                        <b-tab @click="getFeedbackReview();">
                            <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="far fa-user"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">List</span>
                            </template>
                               <div class="row">
      <div class="col-12">
                <div class="row mt-4">

              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                 fixed-header
                 head-variant	=light
                 fixed
              >
               

                <template  v-slot:cell(Reply)="data">
                    <span style="cursor:pointer;color:#2069f1;" @click="giveReply(data.item.FeedbackId);"><i class="vsm--icon uil-message">
  
</i></span>
                </template>
                <template  v-slot:cell(Action)="data">
                    
<b-button v-if="data.item.isActive==1" style="cursor:pointer; " @click="muteFeedback(data.item.FeedbackId);" variant="danger">Mute <i class="vsm--icon uil-comment-slash">  </i>

</b-button>
<b-button v-if="data.item.isActive==0" style="cursor:pointer;" @click="unMuteFeedback(data.item.FeedbackId);" variant="warning">Unmute <i class="vsm--icon uil-comment-verify">
  
</i></b-button>

                </template>


              <template  v-slot:cell(allReplies)="data">
                  
                    <span v-if="data.item.allReplies!=''">
                      <b-button style="cursor:pointer; " @click="seeDetailsReply(data.item.allReplies);" variant="success">View Details<i class="vsm--icon uil-comments">  </i>

</b-button>
                      
                       <!-- <p v-for="item in data.item.allReplies" :key="item.message">
     {{item.replyText | truncate(10, '...')}} <br/>
     <b-button v-if="item.userReply!=null" style="cursor:pointer; " @click="seeDetailsReply(item.replyText,item.userReply);" variant="danger">View Details<i class="vsm--icon uil-comment-slash">  </i>

</b-button>
<b-button v-else-if="item.adminReply!=null" style="cursor:pointer; " @click="seeDetailsReply(item.replyText,item.adminReply);" variant="danger">View Details<i class="vsm--icon uil-comment-slash">  </i>

</b-button>
</p> -->
                    </span>
                    <span v-else>No Reply Yet</span>

                </template>
              </b-table>
                
            </div>
            <div class="row"> 
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </div>
    <!----END OF PRODUCT BY VALUE---->
                        </b-tab>


                        
                         
                    </b-tabs>

 
                </div>
            </div>
        </div> 


        
                    <b-modal id="modal-lg-reply"   size="lg" centered><div class="container deliveryAddContainer"><h4>Add Reply</h4>
                    <!-- <input class="form-control" type="text"  v-model="replyField" placeholder="Type Your Reply"> -->
                    <textarea class="form-control" v-model="replyField" rows="4" placeholder="Type Your Reply" cols="50"
                    :disabled="isEdit"  track-by="replyField" :class="{
                            'is-invalid': submitted && $v.replyField.$error,
                          }"></textarea>
                          <div
                          v-if="submitted && $v.replyField.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.replyField.required"
                            >This value is required.</span
                          >
                        </div> 
                        

        </div><template #modal-footer>
        <div class="w-100">
             <b-spinner v-if="loading"
                                  class="m-2 col-3"
                                  variant="primary"
                                  role="status"
                                ></b-spinner>
			
				<b-button
					class="float-left btnSave btn btn-warning"
					@click="saveReply"
				>
				<span>
					Submit
				</span>
				</b-button>
                <!-- / footer Btn end -->
        </div>
      </template>
                    </b-modal>


                <b-modal id="modal-lg-repliedDetails"   size="lg" centered><div class="container deliveryAddContainer">
                    <!-- <input class="form-control" type="text"  v-model="replyField" placeholder="Type Your Reply"> -->
                    <table class="table" style="width:100%">
  <thead>
    <tr>      
      <th scope="col" >Replied Text</th>
      <th scope="col">Replied By Admin</th>
      <th scope="col">Replied By User</th>
      
    </tr>
  </thead>
  <tbody>

       
    <tr v-for="item in this.repliedTextArr" :key="item.message">    
      <td >{{item.replyText}}</td>
      <td>{{item.adminReply}}</td>
      <td>{{item.userReply}}</td>      
    </tr>
    
  </tbody>
</table>
                     </div>
                    </b-modal>     

  </Layout> 

  
   
</template>
<style lang="scss" scoped>
  /** or use this class: table thead th.bTableThStyle { ... } */
  .table .bTableThStyle {
    max-width: 12rem !important;
    text-overflow: ellipsis !important;
    background: #000;
  }
  .alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}

</style>

